import { red60 } from '@carbon/colors';
import { ArrowRight, AlignBoxTopLeft, TrashCan } from '@carbon/icons-react';
import { Loading, Modal } from '@carbon/react';

import styles from './DeleteModal.module.scss';

export type DeleteModalProps = {
  open: boolean;
  modalLabel: string;
  modalHeading: string;
  objectIcon?: typeof TrashCan;
  loading?: boolean;
  onClose: () => void;
  onDelete: () => void;
};

export const DeleteModal: React.FunctionComponent<DeleteModalProps> = ({
  open,
  modalLabel,
  modalHeading,
  objectIcon = AlignBoxTopLeft,
  loading = false,
  onClose,
  onDelete,
}) => {
  const Icon = objectIcon;

  return (
    <Modal
      open={open}
      size="sm"
      modalLabel={modalLabel}
      modalHeading={<p>{modalHeading}</p>}
      danger
      primaryButtonText="Delete"
      secondaryButtonText="Cancel"
      onRequestSubmit={onDelete}
      onRequestClose={onClose}
    >
      <Loading withOverlay={true} active={loading} />
      <div className={styles.modalBodyContent}>
        <Icon size={80} />
        <ArrowRight color={red60} size={40} />
        <TrashCan size={80} />
      </div>
    </Modal>
  );
};
